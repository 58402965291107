/**
 * Services are generated and returned via a getter function
 * to avoid a complex DI system, until it is necessary
 */
import {
  AccountService,
  AuthenticationService,
  AuthorizationService,
  BankingReferenceService,
  BeneficiaryService,
  BrandingService,
  ClientGroupService,
  ClientNotificationSettingsService,
  ClientService,
  ComplianceService,
  CustomerReferenceService,
  FeesAndChargesService,
  IndividualService,
  LimitsService,
  CreditService,
  NotificationService,
  PartnerGroupService,
  PartnerNotificationSettingsService,
  PartnerService,
  PricingEngineService,
  RiskReportsService,
  RateService,
  ClientInviteService,
  TradeService,
  RegistrationService,
  WalletService,
  SpreadsService,
  FinancialReportsService,
  UpdatesService,
  DocumentsService,
  TradeReportsService,
  FxReferenceService,
  PaymentsService,
  TermsheetService,
  FeatureFlagService,
  FxLiquidityProvidersService,
  ProductService,
  ProductCatalogueService,
  ClientCollateralService,
} from 'ah-api-gateways';
import Vue from 'vue';
import { CustomAxiosRequestConfig, HttpNotifier, HttpService, ConsoleLogService, SocketService } from 'ah-requests';
import { AppConfig } from './config';
import { ToastType } from 'ah-common-lib/src/toast/toastInterfaces';

export interface Services {
  account: AccountService;
  auth: AuthenticationService;
  authz: AuthorizationService;
  bankingReference: BankingReferenceService;
  beneficiary: BeneficiaryService;
  branding: BrandingService;
  client: ClientService;
  clientGroup: ClientGroupService;
  clientInvites: ClientInviteService;
  clientNotificationSettings: ClientNotificationSettingsService;
  credit: CreditService;
  compliance: ComplianceService;
  customerReference: CustomerReferenceService;
  fees: FeesAndChargesService;
  http: HttpService;
  socket: SocketService;
  individual: IndividualService;
  limits: LimitsService;
  notification: NotificationService;
  partner: PartnerService;
  partnerGroup: PartnerGroupService;
  partnerNotificationSettings: PartnerNotificationSettingsService;
  pricingEngine: PricingEngineService;
  risk: RiskReportsService;
  financial: FinancialReportsService;
  rates: RateService;
  trade: TradeService;
  tradeReports: TradeReportsService;
  registration: RegistrationService;
  wallet: WalletService;
  spreads: SpreadsService;
  updates: UpdatesService;
  documents: DocumentsService;
  fxReference: FxReferenceService;
  payments: PaymentsService;
  termsheetService: TermsheetService;
  featureFlag: FeatureFlagService;
  fxLiquidityProviders: FxLiquidityProvidersService;
  products: ProductService;
  productCatalogueService: ProductCatalogueService;
  collateralProfile: ClientCollateralService;
}

let services: Services | null = null;

export function setupServices(config: AppConfig): Services {
  if (!services) {
    const log = new ConsoleLogService(config.isProduction);
    const notifications: HttpNotifier = {
      error(message) {
        Vue.toast.error(message.message!, {
          ...message,
          // FIXME TR: we force all http errors to throw a 'danger' type message, until it is possible to set per-platform defaults
          toastType: ToastType.danger,
        });
      },
    };
    const http = new HttpService(log, notifications);
    const socket = new SocketService();

    if (!config.isProduction) {
      http.interceptRequest((httpConfig: CustomAxiosRequestConfig<any>) => {
        const isRefererAwareService = httpConfig.url?.startsWith(config.customerAPIGatewayUrl);

        if (isRefererAwareService && config.altReferer) {
          httpConfig.headers['x-alternate-referer'] = config.altReferer;
        }
        return httpConfig;
      });
    }

    const builtServices: Services = {
      http,
      socket,
      // Customer APIs
      account: new AccountService(http, config.customerAPIGatewayUrl),
      auth: new AuthenticationService(http, config.customerAPIGatewayUrl),
      authz: new AuthorizationService(http, config.customerAPIGatewayUrl, config.customerAPIGatewayUrl),
      branding: new BrandingService(http, config.customerAPIGatewayUrl),
      featureFlag: new FeatureFlagService(http, config.customerAPIGatewayUrl),
      client: new ClientService(http, config.customerAPIGatewayUrl),
      clientGroup: new ClientGroupService(http, config.customerAPIGatewayUrl),
      clientInvites: new ClientInviteService(http, config.customerAPIGatewayUrl),
      clientNotificationSettings: new ClientNotificationSettingsService(http, config.customerAPIGatewayUrl),
      compliance: new ComplianceService(http, config.customerAPIGatewayUrl),
      customerReference: new CustomerReferenceService(http, config.customerAPIGatewayUrl),
      individual: new IndividualService(http, config.customerAPIGatewayUrl),
      notification: new NotificationService(http, socket, config.customerAPIGatewayUrl),
      partner: new PartnerService(http, config.customerAPIGatewayUrl),
      partnerGroup: new PartnerGroupService(http, config.customerAPIGatewayUrl),
      partnerNotificationSettings: new PartnerNotificationSettingsService(http, config.customerAPIGatewayUrl),
      registration: new RegistrationService(http, config.customerAPIGatewayUrl),
      updates: new UpdatesService(http, config.customerAPIGatewayUrl),
      documents: new DocumentsService(
        http,
        config.customerAPIGatewayUrl,
        config.analyticsUrl,
        config.fxAPIGatewayUrl,
        config.bankingAPIGatewayUrl
      ),
      productCatalogueService: new ProductCatalogueService(
        http,
        config.customerAPIGatewayUrl,
        config.customerAPIGatewayAdminUrl
      ),

      // Banking APIs
      bankingReference: new BankingReferenceService(http, config.bankingAPIGatewayUrl),
      beneficiary: new BeneficiaryService(http, config.bankingAPIGatewayUrl),
      fees: new FeesAndChargesService(http, config.bankingAPIGatewayUrl, config.analyticsUrl),
      wallet: new WalletService(http, config.bankingAPIGatewayUrl, config.bankingAPIGatewayUrl),
      payments: new PaymentsService(http, config.bankingAPIGatewayUrl),

      // FX APIs
      credit: new CreditService(http, config.bankingAPIGatewayUrl),
      limits: new LimitsService(http, config.fxAPIGatewayUrl),
      pricingEngine: new PricingEngineService(http, config.fxAPIGatewayUrl),
      rates: new RateService(http, config.fxAPIGatewayUrl),
      trade: new TradeService(http, config.fxAPIGatewayUrl, config.fxAPIGatewayAdminUrl),
      spreads: new SpreadsService(http, config.fxAPIGatewayUrl),
      fxReference: new FxReferenceService(http, config.fxAPIGatewayUrl),
      termsheetService: new TermsheetService(http, config.fxAPIGatewayUrl),
      fxLiquidityProviders: new FxLiquidityProvidersService(http, config.fxAPIGatewayUrl),
      products: new ProductService(http, config.fxAPIGatewayUrl, config.fxAPIGatewayAdminUrl),
      collateralProfile: new ClientCollateralService(http, config.fxAPIGatewayUrl),

      // Analytics APIs
      risk: new RiskReportsService(http, config.analyticsUrl),
      financial: new FinancialReportsService(http, config.analyticsUrl),
      tradeReports: new TradeReportsService(http, config.analyticsUrl),
    };

    services = builtServices;
  }
  return services;
}

export function useServices(): Services {
  if (!services) {
    throw 'Services used befoe being setup!';
  }
  return services;
}
